<template>
  <div class="container px-0 h-100">
    <div class="wide-box-full">
      <div class="card border-radius-1 mb-4 pb-4">
        <div
          class="card-header-profile border-bottom d-flex align-items-end pb-4"
        >
          <div class="card-title-profile flex-grow-1">
            <div class="d-flex flex-row">
              <div class="px-2">
                <img src="/media/brand/profile-avatar.png" alt="" />
              </div>
              <div class="p-4 profile-fullname align-middle">
                Izabela<br />
                Słowikowska
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">113 zł</h3>
                  <span class="sub-title p-1">Prowizja 09/21</span>
                </div>
              </div>
              <div class="px-4">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">10 polis</h3>
                  <span class="sub-title p-1">Wznowienia 09/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">30 polis</h3>
                  <span class="sub-title p-1">Sprzedaż 08/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">525 zł</h3>
                  <span class="sub-title p-1">Prowizja 08/21</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex justify-content-center flex-row nav-tabs profile-nav-tabs">
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-account' }">
                Konto
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-policies' }">
                Polisy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-renewals' }">
                Wznowienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-issues' }">
                Sprawy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-leads' }">
                Leady
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-settlements' }">
                Rozliczenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-calendar' }">
                Kalendarz
              </router-link>
            </div>
            <div class="profile-nav-link nav-link active">
              <router-link :to="{ name: 'profile-trainings' }">
                Szkolenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-permissions' }">
                Uprawnienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-rodoidd' }">
                RODO/IDD
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <div class="profile-trainings-border">1</div>
                  <div class="profile-trainings-border mt-4">2</div>
                  <div class="profile-trainings-border mt-4">3</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card border-1">
                <div class="card-body">
                  <div class="profile-trainings-border">4</div>
                  <div class="profile-trainings-border mt-4">5</div>
                  <div class="profile-trainings-border mt-4">6</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card border-radius-1 mt-4 pt-4">
        <div class="card-header d-flex align-items-end">
          <div class="card-title flex-grow-1">
            <h3 class="text-secondary">Moje szkolenia</h3>
          </div>
        </div>
        <div class="card-body">
          <b-table
            :items="policiesList.items"
            :fields="policiesList.fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            thead-class="profile-tblheader-normal"
            responsive="sm"
            show-empty
            class="pt-2"
          >
            <template #cell(status)="row">
              <!--              <b-badge pill variant="bg-warning">{{ row.value }}</b-badge>-->
              <span class="badge rounded-pill bg-info">{{ row.value }}</span>
            </template>

            <template #cell(actions)="row">
              <button type="button" class="btn btn-outline-primary btn-sm">Zrezygnuj</button>
            </template>
          </b-table>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "ProfileTrainingsPageView",
  data() {
    return {
      selected: [],
      sortBy: "data",
      sortDesc: false,
      policiesList: {
        fields: [
          {
            key: "ubezpieczyciel",
            label: "Ubezpieczyciel",
            sortable: true
          },
          {
            key: "temat",
            label: "Temat",
            sortable: false
          },
          {
            key: "szczegoly",
            label: "Szczegóły",
            sortable: false
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "",
            sortable: false
          }
        ],
        items: [
          {
            ubezpieczyciel: "LINK4",
            temat: "Egzamin KNF - 48h majątkowy",
            szczegoly: "15.10.2021r. - 11:30",
            status: "Zapisano"
          },
          {
            ubezpieczyciel: "PZU",
            temat: "Auto Assistance za granicą",
            szczegoly: "10.11.2020r. - 12:30",
            status: "Dokumenty w TU"
          },
          {
            ubezpieczyciel: "Generali",
            temat: "Nowoczesne metody wyceny szkód",
            szczegoly: "03.07.2020r. - 9:30",
            status: "Zakończono"
          }
        ]
      }
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
